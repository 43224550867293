import { InputComponent, Button } from "@/components";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    InputComponent,
    Button
  },
  data: () => ({
    form: {
      email: "",
      password: "",
      keepLoggedIn: false
    }
  }),
  computed: {
    ...mapGetters('account', {
      loginIsLoading: 'getLoadingState',
    }),
  },
  methods: {
    ...mapActions("account", {
      loginAction: "LOGIN"
    }),
    login() {
      if (this.form.email && this.form.password) {
        this.loginAction(this.form)
          .then(() => {
            this.$router.push({ name: "Overview" });
          });
      }
    }
  }
};
