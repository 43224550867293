<template>
	<div class="c-login">
		<div class="o-container o-container--full">
			<div class="grid">
				<div class="row">
					<div class="col-md-6">
						<div class="c-login__left">
							<div class="c-login__switch">
								Ben je nog geen lid?
								<router-link :to="{ name: 'Register' }" title="Create account">
									Account aanmaken
								</router-link>
							</div>
							<div class="c-login__form">
								<h1>Login</h1>
								<InputComponent
									v-model="form.email"
									input-type="email"
									label="E-mailadres"
									autocomplete="email"
								/>
								<InputComponent
									v-model="form.password"
									input-type="password"
									label="Wachtwoord"
									autocomplete="password"
									@keyup.enter="login()"
								/>
								<div class="c-login__options">
									<div>
										<input
											id="keepLoggedIn"
											v-model="form.keepLoggedIn"
											type="checkbox"
											name="keepLoggedIn"
										/>
										<label for="keepLoggedIn">Houd me aangemeld</label>
									</div>
									<router-link :to="{ name: 'ForgotPassword' }" title="Forgot Password">
										Wachtwoord vergeten
									</router-link>
								</div>
								<Button :loading="loginIsLoading" @click="login()"> Log in </Button>
							</div>
						</div>
					</div>
					<div class="col-md-6 u-tablet-hide">
						<div class="c-login__right">
							<div class="c-login__logo">
								<img src="~@/assets/images/logo-red.svg" alt="" />
							</div>
							<div class="c-login__visual">
								<img src="~@/assets/images/login-visual.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Login.controller.js"></script>
<style lang="scss" src="./Login.styles.scss"></style>
